import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { getClientList } from "../../service";

const useStyles = makeStyles(theme => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

export default function ControlledOpenSelect(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  let [ClientList, setClientList] = React.useState([]);

  React.useEffect(() => {
    if (ClientList.length === 0 && props.accessToken) {
      getClientList(props.accessToken).then(client_list => {
        setClientList(client_list);
        // console.log(ClientList);
      });
    }
  });

  const handleChange = event => {
    props.handleProfileClientChange(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    // console.log("Client_id selected for SKY user");
    // console.log(ClientList);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <h3>Select Client ID</h3>
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-controlled-open-select-label">
            Client ID
          </InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={props.profileClient}
            onChange={handleChange}
          >
            {ClientList.map(eachClient => (
              <MenuItem value={eachClient}>{eachClient}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
