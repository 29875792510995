import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import ErrorImage from "../../../../components/Images/Error/Error.jpg";
import SuccessImage from "../../../../components/Images/Success/Success.jpg";

const useStyles = makeStyles({
  card: {
    maxWidth: 800,
    minWidth: 300
  }
});

export default function ImgMediaCard(props) {
  const classes = useStyles();
  let Message =
    props.error["flag"] === 1
      ? "Please re-upload as .xlsx"
      : "Your file was successfully uploaded";

  return (
    <Card className={classes.card}>
      <CardMedia
        component="img"
        // alt="VALIDATION SUCCESSFUL"
        height="140"
        src={props.error["flag"] === 1 ? ErrorImage : SuccessImage}
        // title="Validation Successful"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {Message}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <div className="display-linebreak">{props.error["summary"]}</div>
        </Typography>
      </CardContent>
    </Card>
  );
}
