import axios from 'axios';
import { AUTH_CONFIG } from './auth/auth0-variables';


export function zeroFill( number, width )
{
  width -= number.toString().length;
  if ( width > 0 )
  {
    return new Array( width + (/\./.test( number ) ? 2 : 1) ).join( '0' ) + number;
  }
  return number + ""; // always return a string
}

export function parseClient(groups) {
  for (let g in groups) {
    if (groups[g].match(/UploadApp/)) {
      return groups[g].split(' ')[0]
    } 
  }
  return 'ZZZ';
}

export function makeItemData(accessObject) {
  console.log(accessObject);
  return axios.get(AUTH_CONFIG.callbackUrl + '/items',{
    responseType: 'text',
    params: {
      groupAccess : accessObject
    }
  }).then((response) => {
    return response.data;
  }).catch((error) => {
    console.log(error);
  });
}

export function assignAccess(client_id) {
  if (client_id === 'SKY') return true;
  else return false;
}

export function loadClients() {

  axios.get(AUTH_CONFIG.callbackUrl + '/test')
    .then((response) => {

    })
    .catch((error) => {
      console.log(error);
    })

  return "Success?"

}

export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.lenght < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}
