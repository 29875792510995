import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import MailIcon from "@material-ui/icons/Mail";
// import SortByAlphaOutlinedIcon from "@material-ui/icons/SortByAlphaOutlined";
// import HomeIcon from "@material-ui/icons/Home";

const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
});

export default function SideDrawer(props) {
  const classes = useStyles();
  // const sideBarList = props.uploadTypeList.unshift("Home");
  const [state, setState] = React.useState({
    left: false
  });
  // console.log(props);
  // console.log(sideBarList);

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        {["Home", "Style Master","Product Attributes"].map((text, index) => (
          <ListItem button key={text} onClick={() => props.handleSelectSideBar(text)}>
            {/* <ListItemIcon>
              {index === 0 ? (
                <HomeIcon />
              ) : index === 1 ? (
                <SortByAlphaOutlinedIcon />
              ) : index === 2 ? (
                <SortByAlphaOutlinedIcon />
              ) : (
                <MailIcon />
              )}
            </ListItemIcon> */}

            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Drawer open={props.openDrawer} onClose={() => props.handleDrawer()}>
      {sideList("left")}
    </Drawer>
  );
}
