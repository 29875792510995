let BASE_URL = window.location.origin;
if (window.location.hostname === "localhost") {
  BASE_URL = "http://localhost:3081/";
}

export const AUTH_CONFIG = {
  domain: "skypad.auth0.com",
  clientId: "MRU5JBQ8cYP7RmsZBukTSCg4XK4wWRt9",
  callbackUrl: window.location.origin,
  clientName: "Upload",
  BASE_URL: BASE_URL
};
