import history from "../history";
import auth0 from "auth0-js";
import { AUTH_CONFIG } from "./auth0-variables";

export default class Auth {
  accessToken;
  idToken;
  expiresAt;
  userProfile;
  tokenRenewalTimeout;

  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    state: AUTH_CONFIG.clientName,
    audience: `stm_upload`,
    responseType: "token id_token",
    scope: "openid profile"
  });

  userProfile;

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.getExpiryDate = this.getExpiryDate.bind(this);
  }

  login() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        // console.log('checking if auth')
        history.replace("/");
      } else if (err) {
        history.replace("/");
        console.log(
          `Error: ${err.error}. Check the console for further details.`
        );
      }
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    localStorage.setItem("nonce", authResult.idTokenPayload.nonce);

    // Set isLoggedIn flag in localStorage
    localStorage.setItem("isLoggedIn", "true");

    // schedule a token renewal
    //this.scheduleRenewal();

    // navigate to the home route
    //history.replace('/');
  }

  getAccessToken() {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      throw new Error("No access token found");
    }
    return accessToken;
  }

  // getAccessToken() {
  //   return this.accessToken;
  // }

  getIdToken() {
    return localStorage.getItem("id_token");
  }

  getProfile(cb) {
    let accessToken = this.getAccessToken();
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      cb(err, profile);
    });
  }

  logout() {
    // Clear token renewal
    //clearTimeout(this.tokenRenewalTimeout);
    //localStorage.removeItem('token_renewal_timeout');

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("access_token");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("nonce");

    // Clear token renewal
    //clearTimeout(this.tokenRenewalTimeout);

    // Remove SSO token as well
    this.auth0.logout({
      returnTo: AUTH_CONFIG.callbackUrl,
      client_id: AUTH_CONFIG.clientId
    });

    // navigate to the home route
    //history.replace('/');
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  }

  renewSession() {
    this.auth0.checkSession(
      {
        nonce: localStorage.getItem("nonce")
      },
      (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
        } else if (err) {
          this.logout();
          console.log(err);
          alert(`You were logged out!`);
        }
      }
    );
  }

  scheduleRenewal() {
    console.log("Renewing session...");
    //let expiresAt = localStorage.getItem('expires_at');
    //const timeout = expiresAt - Date.now();
    const timeout = 30000;
    //console.log(timeout);
    if (timeout > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession();
      }, timeout);
    }
  }

  getExpiryDate() {
    return JSON.stringify(new Date(localStorage.getItem("expires_at")));
  }
}
