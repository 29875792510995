import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SkypadLogo = () => (
  <SvgIcon width="131pt" height="24pt" viewBox="0 0 131 24">
    <g>
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "rgb(100%,100%,100%)",
          fillOpacity: 1
        }}
        d="M 0 0 L 0 19.851562 C 1.117188 19.019531 2.230469 17.664062 2.867188 16.421875 C 5.050781 18 7.398438 19.496094 10.214844 19.292969 C 11.664062 19.1875 13.933594 18.042969 12.769531 16.25 C 12.148438 15.289062 10.871094 15.003906 9.855469 14.679688 C 7.703125 13.988281 5.285156 13.664062 3.40625 12.308594 C 0.796875 10.425781 0.0546875 6.585938 1.699219 3.789062 C 2.941406 1.675781 5.269531 0.289062 7.707031 0.179688 C 6.949219 -0.140625 6.011719 0 5.195312 0 Z M 0 0 "
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "rgb(31.372549%,72.54902%,28.235294%)",
          fillOpacity: 1
        }}
        d="M 2.867188 16.421875 C 2.21875 17.289062 1.503906 18.101562 0.824219 18.949219 C 0.546875 19.289062 0.101562 19.71875 0.164062 20.203125 C 0.234375 20.734375 0.859375 21.109375 1.253906 21.378906 C 2.300781 22.097656 3.464844 22.695312 4.660156 23.121094 C 8.917969 24.644531 15.976562 24.636719 17.824219 19.488281 C 18.695312 17.054688 18.476562 13.941406 16.484375 12.125 C 15.066406 10.832031 13.265625 10.261719 11.46875 9.746094 C 10.078125 9.34375 8.40625 9.101562 7.171875 8.300781 C 6.527344 7.886719 5.929688 7.132812 6.203125 6.316406 C 7.226562 3.277344 11.683594 5.222656 13.441406 6.191406 C 13.980469 6.488281 14.476562 6.816406 15.054688 7.039062 C 15.578125 6.085938 16.238281 5.230469 16.839844 4.332031 C 17.070312 3.984375 17.4375 3.53125 17.378906 3.085938 C 17.320312 2.625 16.832031 2.339844 16.484375 2.109375 C 15.460938 1.429688 14.265625 0.898438 13.082031 0.570312 C 9.023438 -0.550781 3.191406 -0.230469 1.339844 4.332031 C 0.28125 6.941406 0.777344 10.40625 3.046875 12.175781 C 4.558594 13.355469 6.601562 13.84375 8.421875 14.324219 C 9.820312 14.6875 13.96875 15.597656 12.820312 17.859375 C 12.390625 18.703125 11.453125 19.0625 10.574219 19.222656 C 8.5625 19.589844 6.316406 18.667969 4.660156 17.585938 C 4.054688 17.191406 3.511719 16.746094 2.867188 16.421875 Z M 2.867188 16.421875 "
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "rgb(100%,100%,100%)",
          fillOpacity: 1
        }}
        d="M 10.394531 0 C 11.109375 0.3125 11.960938 0.328125 12.722656 0.523438 C 13.777344 0.792969 14.824219 1.238281 15.769531 1.78125 C 16.28125 2.074219 17.296875 2.546875 17.257812 3.253906 C 17.234375 3.710938 16.835938 4.15625 16.589844 4.511719 C 16.023438 5.324219 15.40625 6.105469 15.054688 7.039062 C 13.019531 5.902344 10.875 4.457031 8.421875 4.722656 C 6.929688 4.882812 5.238281 6.390625 6.527344 7.902344 C 7.269531 8.769531 8.804688 9.066406 9.855469 9.355469 C 11.976562 9.941406 14.183594 10.421875 15.949219 11.824219 C 18.675781 13.988281 19.042969 18.171875 16.929688 20.933594 C 15.550781 22.730469 13.351562 23.773438 11.109375 23.820312 C 12.089844 24.234375 13.460938 24 14.515625 24 L 131 24 L 131 12.632812 C 130.742188 13.246094 130.773438 13.960938 130.617188 14.617188 C 130.296875 15.976562 129.792969 17.242188 129.011719 18.40625 C 124.820312 24.640625 117.246094 23.640625 110.75 23.640625 L 110.75 0.359375 C 117.039062 0.359375 124.304688 -0.640625 128.628906 5.050781 C 129.261719 5.886719 129.800781 6.769531 130.160156 7.757812 C 130.589844 8.945312 130.816406 10.105469 130.820312 11.367188 C 131.25 10.339844 131 8.871094 131 7.757812 L 131 0 Z M 10.394531 0 "
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "rgb(50.196078%,50.196078%,51.764706%)",
          fillOpacity: 1
        }}
        d="M 84.046875 23.640625 C 84.597656 23.640625 85.429688 23.789062 85.90625 23.453125 C 86.335938 23.148438 86.554688 22.480469 86.761719 22.015625 C 87.242188 20.929688 87.730469 19.847656 88.226562 18.765625 C 88.441406 18.289062 88.675781 17.625 89.136719 17.328125 C 89.851562 16.867188 91.292969 17.144531 92.113281 17.144531 L 99.28125 17.144531 C 100.121094 17.144531 101.558594 16.867188 102.257812 17.433594 C 102.839844 17.90625 103.082031 18.824219 103.378906 19.488281 C 103.980469 20.851562 104.785156 22.207031 105.195312 23.640625 L 107.34375 23.640625 C 106.871094 21.625 105.644531 19.746094 104.8125 17.863281 C 102.964844 13.691406 101.058594 9.515625 99.058594 5.414062 C 98.480469 4.226562 97.910156 3.015625 97.386719 1.804688 C 97.015625 0.953125 96.746094 0.1875 95.695312 0.1875 C 94.648438 0.1875 94.375 0.953125 94.007812 1.804688 C 93.480469 3.015625 92.914062 4.226562 92.335938 5.414062 C 90.621094 8.925781 89.097656 12.535156 87.40625 16.058594 C 86.257812 18.449219 84.65625 21.050781 84.046875 23.640625 Z M 84.046875 23.640625 "
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "rgb(31.372549%,72.54902%,28.235294%)",
          fillOpacity: 1
        }}
        d="M 20.429688 0.359375 L 20.429688 23.640625 L 25.804688 23.640625 L 25.804688 19.128906 C 25.804688 18.398438 25.660156 17.476562 25.914062 16.78125 C 26.332031 15.636719 27.617188 15.011719 28.136719 13.894531 C 29.972656 16.035156 31.507812 18.472656 33.175781 20.75 C 33.78125 21.585938 34.417969 22.9375 35.335938 23.453125 C 35.882812 23.761719 36.671875 23.640625 37.273438 23.640625 L 41.574219 23.640625 C 41.046875 22.378906 40.027344 21.308594 39.226562 20.210938 C 37.683594 18.105469 36.167969 15.984375 34.609375 13.894531 C 34.027344 13.113281 33.433594 12.34375 32.871094 11.550781 C 32.625 11.203125 32.214844 10.746094 32.25 10.285156 C 32.328125 9.320312 33.953125 8.246094 34.570312 7.578125 C 36.75 5.222656 39.375 2.992188 41.21875 0.359375 L 37.097656 0.359375 C 36.425781 0.359375 35.585938 0.230469 34.945312 0.46875 C 33.921875 0.855469 33.007812 2.285156 32.265625 3.066406 C 30.074219 5.375 28.023438 7.824219 25.804688 10.105469 L 25.804688 0.359375 L 20.429688 0.359375 M 41.753906 0.359375 C 42.1875 1.722656 43.195312 2.953125 43.960938 4.148438 C 45.375 6.363281 46.738281 8.613281 48.152344 10.828125 C 48.878906 11.96875 49.878906 13.164062 50.359375 14.4375 C 50.707031 15.363281 50.535156 16.53125 50.535156 17.503906 L 50.535156 23.640625 L 55.734375 23.640625 L 55.734375 17.503906 C 55.734375 16.53125 55.5625 15.363281 55.910156 14.4375 C 56.410156 13.109375 57.472656 11.832031 58.242188 10.648438 C 59.597656 8.554688 60.894531 6.425781 62.246094 4.332031 C 63.046875 3.085938 64.097656 1.792969 64.515625 0.359375 L 60.570312 0.359375 C 60.039062 0.359375 59.335938 0.246094 58.839844 0.46875 C 58.011719 0.839844 57.433594 2.320312 56.988281 3.066406 C 55.734375 5.164062 54.621094 7.515625 53.046875 9.382812 C 52.199219 7.050781 50.515625 4.835938 49.246094 2.707031 C 48.84375 2.03125 48.390625 0.800781 47.632812 0.46875 C 47.078125 0.230469 46.289062 0.359375 45.699219 0.359375 Z M 41.753906 0.359375 "
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "rgb(50.196078%,50.196078%,51.764706%)",
          fillOpacity: 1
        }}
        d="M 68.816406 0.359375 L 68.816406 23.640625 L 70.785156 23.640625 L 70.785156 14.976562 C 74.621094 14.976562 79.160156 15.691406 82.613281 13.671875 C 86.914062 11.15625 87.332031 4.417969 82.972656 1.707031 C 80.855469 0.390625 78.382812 0.359375 75.984375 0.359375 L 68.816406 0.359375 M 110.75 0.359375 L 110.75 23.640625 C 115.847656 23.640625 121.710938 24.503906 126.160156 21.457031 C 133.015625 16.761719 132.316406 5.523438 124.90625 1.769531 C 120.648438 -0.386719 115.363281 0.359375 110.75 0.359375 Z M 110.75 0.359375 "
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "rgb(100%,100%,100%)",
          fillOpacity: 1
        }}
        d="M 70.785156 2.164062 L 70.785156 13.351562 C 74.136719 13.351562 78.539062 14.007812 81.539062 12.183594 C 84.953125 10.109375 85.027344 4.847656 81.359375 2.996094 C 78.316406 1.460938 74.09375 2.164062 70.785156 2.164062 M 112.722656 2.164062 L 112.722656 21.835938 C 116.375 21.835938 120.464844 22.40625 123.832031 20.683594 C 130.035156 17.515625 130.71875 8.214844 125.085938 4.113281 C 121.515625 1.511719 116.867188 2.164062 112.722656 2.164062 M 95.515625 2.527344 C 94.011719 6.976562 91.015625 10.984375 89.78125 15.519531 L 101.609375 15.519531 C 100.367188 12.472656 98.9375 9.46875 97.535156 6.496094 C 96.933594 5.226562 96.496094 3.542969 95.515625 2.527344 M 0 20.390625 L 0 24 L 8.421875 24 C 7.332031 23.535156 5.988281 23.535156 4.839844 23.121094 C 3.046875 22.480469 1.59375 21.390625 0 20.390625 Z M 0 20.390625 "
      />
    </g>
  </SvgIcon>
);

export default SkypadLogo;
