import React from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  root: {
    width: "50%",
    position: "relative",
    marginTop: "40px",
    marginLeft: "25%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  text: {
    marginTop: "10px",
    textAlign: "center",
    color: "green",
    fontSize: "25px"
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: green
  }
});

export default function LinearIndeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <LinearProgress />
        <div className={classes.text}>Validating...</div>
      </ThemeProvider>
    </div>
  );
}
