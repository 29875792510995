import React from "react";
import { Helmet } from "react-helmet";
import { Route, Router, Switch } from "react-router-dom";

import "./App.css";
import Home from "./modules/Home/Home";
import Auth from "./auth/auth";
import history from "./history";
// import SignInSide from "./modules/SignIn/SignInSide";
// import SignIn from "./modules/SignIn/SignIn";
// import "bootstrap/dist/css/bootstrap.min.css";

const auth = new Auth();
const TITLE = "SKYPAD Upload";

const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication();
  }
};

function App() {
  return (
    <div className="application">
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <title>{TITLE}</title>
      </Helmet>
      <div className="App">
        <Router history={history}>
          <Switch>
            <Route
              path="/"
              render={props => {
                handleAuthentication(props);
                return <Home auth={auth} {...props} />;
                // return <SignInSide />;
              }}
            />
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
