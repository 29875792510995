import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 290
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

export default function VerticalTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabpanel_styles = {
    fontWeight: "normal",
    textAlign: "left",
    minWidth: 800,
    maxWidth: 800
  };

  const tabpanel_styles_italic = {
    fontStyle: "italic",
    fontWeight: "normal",
    textAlign: "left",
    minWidth: 800,
    maxWidth: 800
  };

  return (
    <div className={classes.root}>
      <Tabs
        inkBarStyle={{ background: "green" }}
        orientation="vertical"
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        {props.error.map((eachError, index) => {
          return (
            <Tab
              label={eachError.label}
              style={{
                background:
                  eachError.issueCode === 0
                    ? "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 71%, rgba(44,166,9,1) 100%)"
                    : "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(249,250,248,1) 71%, rgba(255,255,255,0.88) 72%, rgba(249,9,21,1) 100%)",
                fontWeight: value === index ? "bold" : ""
              }}
              {...a11yProps(eachError.id - 1)}
            />
          );
        })}
      </Tabs>
      {props.error.map(eachError => (
        <TabPanel value={value} index={eachError.id - 1}>
          {eachError.name === "column_headers" ||
          eachError.name === "empty_columns" ||
          eachError.name === "unique_upcs" ? (
            <div>
              <div className="display-linebreak" style={tabpanel_styles}>
                {eachError.issueMessage.split("!")[0]}
              </div>
              <div className="display-linebreak" style={tabpanel_styles_italic}>
                {eachError.issueMessage.split("!")[1]}
              </div>
            </div>
          ) : (
            <div>
              <div className="display-linebreak" style={tabpanel_styles}>
                {eachError.issueMessage}
              </div>
            </div>
          )}
        </TabPanel>
      ))}
    </div>
  );
}
