import Axios from "axios";
import fileSaver from "file-saver";
import { AUTH_CONFIG } from "../../auth/auth0-variables";

export function downloadTemplate(type, profileClient, uploadType) {

  Axios.get(AUTH_CONFIG.BASE_URL + `/downloadTemplate`, {
    responseType: "blob",
    params: {
      type: type,
      profileClient: profileClient,
      uploadType: uploadType
    }
  }).then(data => {
    let fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    var blob = new Blob([data.data], { type: fileType });
    let file = `template.${type}`;
    fileSaver.saveAs(blob, file);
  })
    .catch(error => {
      console.log(error);
    });
}

export function uploadFile(
  templateFile,
  clientId,
  userId,
  username,
  securityGroup,
  uploadType,
  access_token
) {
  let file = new FormData();
  file.append("file", templateFile);
  return Axios.post(AUTH_CONFIG.BASE_URL + `/uploadfile`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
      clientId: clientId,
      userId: userId,
      username: username,
      securityGroup: securityGroup,
      uploadType: uploadType,
      Authorization: "Bearer " + access_token
    }
  })
    .then(data => {
      return data && data.data;
    })
  // .catch(error => {
  //   console.log(error);
  //   return false;
  // });
}

export function getClientList(access_token) {
  return Axios.get(AUTH_CONFIG.BASE_URL + `/getClientList`, {
    headers: {
      Authorization: "Bearer " + access_token
    }
  })
    .then(data => {
      return data.data.sort();
    })
    .catch(error => {
      console.log(error);
    });
}

export function setClientID(client_id, access_token) {
  return Axios.get(AUTH_CONFIG.BASE_URL + `/setClientID`, {
    headers: {
      Authorization: "Bearer " + access_token
    },
    params: {
      clientID: client_id
    }
  })
    .then(data => {
      // console.log(data.data)
      // return data.data;
    })
    .catch(error => {
      console.log(error);
    });
}

export function setFileType(client_id, access_token) {
  return Axios.get(AUTH_CONFIG.BASE_URL + `/setFileType`, {
    headers: {
      Authorization: "Bearer " + access_token
    },
    params: {
      clientID: client_id
    }
  })
    .then(data => {
      // console.log(data.data)
      return data.data.sort();
    })
    .catch(error => {
      console.log(error);
    });
}