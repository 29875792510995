import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Dropzone from "react-dropzone";
import Grid from "@material-ui/core/Grid";
import jwt_decode from "jwt-decode";
import "./Home.css";

import { parseClient, formatDate, assignAccess } from "../../utils";
import StyleMasterAppBar from "../../components/StyleMasterAppBar";
import {
  downloadTemplate,
  uploadFile,
  setClientID,
  setFileType,
} from "./service";
import SideDrawer from "../Home/components/SideDrawer/SideDrawer";
import VerticalTabs from "../Home/components/VerticalTabs/VerticalTabs";
import MediaCard from "./components/MediaCard/MediaCard";
import OpenSelect from "./components/OpenSelect/OpenSelect";
import { AUTH_CONFIG } from "../../auth/auth0-variables";
import LinearIndeterminate from "../Home/components/LinearBuffer/LinearBuffer";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDrawer: false,
      anchorEl: null,
      errorMessage: null,
      uploadStarted: false,
      isUploading: false,
      profileClient: "",
      username: "",
      userId: "",
      email: "",
      securityGroup: "",
      profilePicture: "",
      modifiedBy: "",
      modifiedDate: "",
      access_token: null,
      fileType: "NULL",
      apiFailed: false,
      uploadType: "",
      uploadTypeList: [],
      // sideBarList: [],
    };

    this.handleHome = this.handleHome.bind(this);
    this.handleDrawer = this.handleDrawer.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSelectSideBar = this.handleSelectSideBar.bind(this);
    this.handleUploadType = this.handleUploadType.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleProfileClientChange = this.handleProfileClientChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.getUploadHeader = this.getUploadHeader.bind(this);
    this.logout = this.logout.bind(this);
  }

  handleHome() {
    window.location.assign(AUTH_CONFIG.BASE_URL);
  }

  handleDrawer() {
    this.setState({ openDrawer: !this.state.openDrawer });
    console.log(this.state.openDrawer);
  }

  handleSelectSideBar(sidebar_options) {
    console.log(sidebar_options);
    if (sidebar_options === "Home") {
      this.setState({ openDrawer: !this.state.openDrawer });
      window.location.assign(AUTH_CONFIG.BASE_URL);
    } else if (sidebar_options === "Style Master") {
      this.setState({
        uploadType: "style_master",
        openDrawer: !this.state.openDrawer,
        uploadStarted: false,
      });
      // console.log(this.state.uploadType);
    } else if (sidebar_options === "Product Attributes") {
      this.setState({
        uploadType: "product_attributes",
        openDrawer: !this.state.openDrawer,
        uploadStarted: false,
      });
      // console.log(this.state.uploadType);
    } else if (sidebar_options === "Location Attributes") {
      this.setState({
        uploadType: "location_attributes",
        openDrawer: !this.state.openDrawer,
        uploadStarted: false,
      });
      // console.log(this.state.uploadType);
    } else {
    }
  }

  handleClick(event) {
    downloadTemplate("xlsx", this.state.profileClient, this.state.uploadType);
  }

  handleUploadType(upload_type) {
    // console.log(this.state.profileClient);
    // this.setState({ anchorEl: event.currentTarget });
    if (upload_type === "style_master") {
      this.setState({ uploadType: "style_master" });
      // console.log(this.state.uploadType);
    } else if (upload_type === "product_attributes") {
      this.setState({ uploadType: "product_attributes" });
      // console.log(this.state.uploadType);
    } else if (upload_type === "location_attributes") {
      this.setState({ uploadType: "location_attributes" });
      // console.log(this.state.uploadType);
    } else {
    }
  }

  handleFileUpload(files) {
    this.setState({ isUploading: true });
    uploadFile(
      files[0],
      this.state.profileClient,
      this.state.userId,
      this.state.username,
      this.state.securityGroup,
      this.state.uploadType,
      this.state.access_token
    )
      .then((error) => {
        this.setState(
          { errorMessage: error, uploadStarted: true, isUploading: false },
          () => {
            // console.log(this.state.errorMessage);
          }
        );
      })
      .catch((err) => {
        this.setState({ isUploading: false, apiFailed: true }, () => {
          console.log(err);
          // console.log(this.state.apiFailed);
        });
      });
  }

  componentDidMount() {
    var id_tok, groups, emails, access_token;

    if (localStorage.getItem("id_token") === null) {
      console.log("Token is null");
    } else {
      // console.log("Retrieved token");
      id_tok = jwt_decode(localStorage.getItem("id_token"));
      access_token = localStorage.getItem("access_token");

      groups = "http://skypad_user/groups";
      emails = "http://skypad_user/email";
      this.setState({ access_token: access_token }, () => {
        // console.log(this.state.access_token);
      });
      localStorage.setItem("user", id_tok.name);
      this.setState({ username: id_tok.name }); // AD username
      //this.setState({client_id: parseClient(id_tok[groups])});
      this.setState({ profileClient: parseClient(id_tok[groups]) }, () => {
        // console.log(this.state.profileClient)
        setClientID(this.state.profileClient, this.state.access_token);
        setFileType(this.state.profileClient, this.state.access_token)
          .then((file_type) => {
            if (file_type.length === 1) {
              this.setState({ uploadType: file_type[0] });
            } else if (file_type.length > 1) {
              this.setState({ uploadTypeList: file_type });
            }
          })
          .catch((err) => {
            this.setState({ apiFailed: true }, () => {
              console.log(err);
            });
          });
      });
      this.setState({ securityGroup: parseClient(id_tok[groups]) });
      this.setState({ email: parseClient(id_tok[emails]) });
      this.setState({ profile_picture: id_tok.picture });
      this.setState({ userId: id_tok.nickname });
      this.setState({ profilePicture: id_tok.picture });
      this.setState({ modifiedBy: id_tok.nickname });
      this.setState({ modifiedDate: formatDate(Date.now()) });
    }
  }

  login() {
    this.props.auth.login();
  }

  renewSession() {
    this.props.auth.renewSession();
  }

  getIdToken() {
    this.props.auth.getIdToken();
  }

  isSuperUser() {
    return assignAccess(this.state.profileClient);
  }

  logout() {
    this.props.auth.logout();
  }

  checkForSkyUser() {
    return this.state.profileClient === "SKY";
  }

  checkForFileType() {
    return this.state.fileType === "NULL";
  }

  handleProfileClientChange(profileClient) {
    this.setState({ profileClient }, () => {
      // console.log(this.state.profileClient);
      setFileType(this.state.profileClient, this.state.access_token)
        .then((file_type) => {
          if (file_type.length === 1) {
            this.setState({ uploadType: file_type[0] });
          } else if (file_type.length > 1) {
            this.setState({ uploadTypeList: file_type });
          }
        })
        .catch((err) => {
          this.setState({ apiFailed: true }, () => {
            console.log(err);
          });
        });
    });
  }

  handleFileChange(fileType) {
    this.setState({ fileType });
  }

  getUploadHeader(uploadType) {
    // console.log(uploadType);
    if (uploadType === "style_master") {
      return "Style Master";
    } else if (uploadType === "product_attributes") {
      return "Product Attributes";
    } else if (uploadType === "location_attributes") {
      return "Location Attributes";
    } else {
      return "";
    }
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    let logOut = this.props.auth.logout;

    const uploadStarted = this.state.uploadStarted;
    const errorMessage = this.state.errorMessage;
    const apiFailed = this.state.apiFailed;
    const uploadTypeList = this.state.uploadTypeList;
    // let sideBarList = this.state.uploadTypeList.unshift("Home");
    const uploadHeader = this.getUploadHeader(this.state.uploadType);
    const upload_dict = {
      style_master: "STYLE MASTER",
      product_attributes: "PRODUCT ATTRIBUTES",
      location_attributes: "LOCATION ATTRIBUTES",
    };
    let uploadFlag;
    let issueTabs;
    let UploadTypeButtons;
    // let sideBarList;
    // let sideBarList = ["Home", "Style Master","Product Attributes","Location Attributes"];
    // let sideBarList = this.state.uploadTypeList;

    // if sideBarList.length
    // sideBarList.unshift("Home")

    // sideBarList = this.state.uploadTypeList.unshift("Home");

    // if (uploadTypeList.length === 2) {
    //   UploadTypeButtons = (
    //     <React.Fragment>
    //       <div style={{ minHeight: "175px" }} />
    //       <Button
    //         variant="outlined"
    //         color="default"
    //         style={{ width: "245px", height: "50px", marginRight: "25px" }}
    //         onClick={() => this.handleUploadType(this.state.uploadTypeList[0])}
    //       >
    //         {upload_dict[this.state.uploadTypeList[0]]}
    //       </Button>
    //       <Button
    //         variant="outlined"
    //         color="default"
    //         style={{ width: "245px", height: "50px", marginLeft: "25px" }}
    //         onClick={() => this.handleUploadType(this.state.uploadTypeList[1])}
    //       >
    //         {upload_dict[this.state.uploadTypeList[1]]}
    //       </Button>
    //     </React.Fragment>
    //   );
    // }

    if (uploadTypeList.length > 1) {
      UploadTypeButtons = (
        <React.Fragment>
          <div style={{ minHeight: "100px" }} />
          {uploadTypeList.map((uploadType) => (
            <React.Fragment>
              <Button
                variant="outlined"
                color="default"
                style={{ width: "245px", height: "50px", marginRight: "25px" }}
                onClick={() => this.handleUploadType(uploadType)}
              >
                {upload_dict[uploadType]}
              </Button>
              <div style={{ minHeight: "50px" }} />
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    }

    if (!apiFailed) {
      if (!uploadStarted) {
        uploadFlag = (
          <div>
            <div style={{ minHeight: "125px" }} />
            <h3>{uploadHeader}</h3>
            <div style={{ minHeight: "125px" }} />
          </div>
        );
      } else if (errorMessage["flag"] === 0) {
        uploadFlag = (
          <div>
            <div style={{ minHeight: "40px" }} />
            <h3>{uploadHeader}</h3>
            <div style={{ minHeight: "40px" }} />
          </div>
        );
      } else if (errorMessage["flag"] === 1) {
        uploadFlag = (
          <div>
            <div style={{ minHeight: "40px" }} />
            <h3>{uploadHeader}</h3>
            <div style={{ minHeight: "40px" }} />
          </div>
        );
      } else {
        uploadFlag = (
          <div>
            <div style={{ minHeight: "15px" }} />
            <h3>{uploadHeader}</h3>
            <div style={{ minHeight: "10px" }} />
          </div>
        );
      }
    }

    if (!apiFailed) {
      if (!uploadStarted) {
        issueTabs = (
          <div>
            <div style={{ minHeight: "25px" }} />
          </div>
        );
      } else if (errorMessage["flag"] === 0) {
        issueTabs = (
          <div>
            <div style={{ minHeight: "60px" }} />
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <MediaCard error={this.state.errorMessage} />
              </Grid>
            </Grid>
          </div>
        );
      } else if (errorMessage["flag"] === 1) {
        issueTabs = (
          <div>
            <div style={{ minHeight: "60px" }} />
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <MediaCard error={this.state.errorMessage} />
              </Grid>
            </Grid>
          </div>
        );
      } else {
        issueTabs = (
          <div>
            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                textDecoration: "underline",
                color: "red",
                fontSize: "20px",
              }}
            >
              Upload Failed. Please correct the below errors, then reupload.
            </div>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <VerticalTabs error={this.state.errorMessage} />
              </Grid>
            </Grid>
          </div>
        );
      }
    }

    return (
      <div>
        {isAuthenticated() && this.state.securityGroup !== "ZZZ" && !apiFailed && (
          <React.Fragment>
            <StyleMasterAppBar
              handleDrawer={this.handleDrawer}
              handleHome={this.handleHome}
              username={this.state.username}
              logOut={logOut}
            />
            <SideDrawer
              handleDrawer={this.handleDrawer}
              openDrawer={this.state.openDrawer}
              handleSelectSideBar={this.handleSelectSideBar}
              // uploadTypeList={this.state.uploadTypeList}
            />

            <div hidden={!this.checkForSkyUser()}>
              <OpenSelect
                profileClient
                handleProfileClientChange={this.handleProfileClientChange}
                accessToken={this.state.access_token}
              />
            </div>

            <div
              hidden={
                this.state.uploadTypeList !== [] && this.state.uploadType === ""
                  ? ""
                  : "hidden"
              }
            >
              {UploadTypeButtons}
            </div>

            <div hidden={this.state.uploadType === "" ? "hidden" : ""}>
              {uploadFlag}
              <Button
                variant="outlined"
                color="default"
                // variant="contained"
                // color="primary"
                style={{
                  width: "300px",
                  height: "50px",
                  marginRight: "25px",
                }}
                onClick={this.handleClick}
              >
                Download Template
                <span style={{ width: "5px" }} />
                <CloudDownloadIcon />
              </Button>
              <Dropzone
                onDrop={(acceptedFiles) => this.handleFileUpload(acceptedFiles)}
                maxSize={100 * 1024 * 1024}
              >
                {({ getRootProps, getInputProps }) => (
                  <Button
                    style={{
                      width: "300px",
                      height: "50px",
                      marginRight: "25px",
                    }}
                    variant="outlined"
                    color="default"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    Upload {uploadHeader}
                    <span style={{ width: "5px" }} />
                    <CloudUploadIcon />
                  </Button>
                )}
              </Dropzone>
              {this.state.isUploading ? <LinearIndeterminate /> : issueTabs}
              <div style={{ minHeight: "100px" }} />
              <div
                style={{
                  // textDecoration: "underline",
                  fontSize: "15px",
                }}
              >
                Please reference the &nbsp;
                <a
                  target="_blank"
                  href="https://skypad.zendesk.com/hc/en-us/categories/360004354512-Upload-App"
                >
                  SKYPAD Help Center
                </a>
                &nbsp;for additional help with the upload process
              </div>
            </div>
          </React.Fragment>
        )}

        {isAuthenticated() && this.state.securityGroup === "ZZZ" && !apiFailed && (
          <React.Fragment>
            <StyleMasterAppBar
              handleHome={this.handleHome}
              username={this.state.username}
              logOut={logOut}
            />
            <div style={{ minHeight: "175px" }} />
            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                // textDecoration: "underline",
                fontSize: "25px",
              }}
            >
              Access Denied. Please reach out to&nbsp;
              <a href="mailto:asquad@skyitgroup.com">asquad@skyitgroup.com</a>
              &nbsp;for further troubleshooting.
            </div>
            <div style={{ minHeight: "125px" }} />
          </React.Fragment>
        )}

        {isAuthenticated() && this.state.securityGroup !== "ZZZ" && apiFailed && (
          <React.Fragment>
            <StyleMasterAppBar
              handleHome={this.handleHome}
              username={this.state.username}
              logOut={logOut}
            />
            <div style={{ minHeight: "175px" }} />
            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                fontSize: "25px",
              }}
            >
              File upload failed. Please reach out to&nbsp;
              <a href="mailto:asquad@skyitgroup.com">asquad@skyitgroup.com</a>
              &nbsp;for further troubleshooting.
            </div>
            <div style={{ minHeight: "125px" }} />
          </React.Fragment>
        )}

        {!isAuthenticated() && this.login()}
      </div>
    );
  }
}

export default Home;
